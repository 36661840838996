import {RequestClient} from "@/service/RequestClient";

const apiClient = new RequestClient("client");

export default {
    getClients(archived) {
        return apiClient.get(`/list-clients/${archived}`);
    },

    getpagedCients(archived,page,size){
        return apiClient.get(`/paged-clients/${archived}/${page}/${size}`)
    },

    getClient(clientId) {
        return apiClient.get(`/${clientId}`);
    },

    saveClient(client) {
        return apiClient.post(`/save`, client);
    },

    archiveClient(id) {
        return apiClient.put(`/archive/${id}`);
    }
};
