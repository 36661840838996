import axios from "axios";
import {TokenService} from "@/service/TokenService";

export class RequestClient {

    constructor(baseUrl, responseType, accept, timeout) {
        const urlAppendix = baseUrl && !baseUrl.startsWith("/") ? "/" + baseUrl : baseUrl || "";
        this.client = axios.create({
            baseURL: process.env.VUE_APP_BASE_URL + '/api' + urlAppendix,
            withCredentials: true,
            timeout: timeout || 30000,
            responseType: responseType || "json",
            headers: {
                "Accept": accept || "application/json",
                "Content-Type": accept || "application/json",
                "Accept-Language": navigator.language
            },
        });
    }

    async post(url, data) {
        return this.client.post(url, data, await this.getHeaders())
    }

    async get(url) {
        return await this.client.get(url, await this.getHeaders());
    }

    getPath(){
        return this.client.getUri()
    }

    async getHeaders() {
        return {
            headers: {
                'Authorization': 'Bearer ' + await TokenService.getValidJwt()
            }
        }
    }

    async delete(url) {
        return await this.client.delete(url, await this.getHeaders());
    }

    async put(url, data) {
        return await this.client.put(url, data, await this.getHeaders())
    }
}